import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import './vista.css'; // Importar el archivo CSS para los estilos

const Vista = () => {
  const [esp32Data, setEsp32Data] = useState(null);
  const [chart, setChart] = useState(null);
  const [ultimoDato, setUltimoDato] = useState(null);

  useEffect(() => {
    const fetchEsp32Data = async () => {
      try {
        const response = await axios.get('https://proyecto-backend-1.onrender.com/api/esp32');
        setEsp32Data(response.data);
        // Setear el último dato
        setUltimoDato(response.data.length > 0 ? response.data[response.data.length - 1] : null);
      } catch (error) {
        console.error('Error al obtener datos del ESP32:', error);
      }
    };

    fetchEsp32Data();
  }, []);

  useEffect(() => {
    if (esp32Data && esp32Data.length > 0) {
      const ultimoDato = esp32Data[esp32Data.length - 1];

      // Destruir el gráfico existente si existe
      if (chart) {
        chart.destroy();
      }

      const ctx = document.getElementById('myChart');

      let chartType = 'bar';

      // Determinar el tipo de gráfico según el tipo de sensor
      if (ultimoDato.sensor_type === 'digital') {
        chartType = 'line';
      }

      // Ajustar los datos del sensor para que muestren 0 si es diferente de 4095
      const sensorData = [
        ultimoDato.sensor_1 === 4095 ? ultimoDato.sensor_1 : 0,
        ultimoDato.sensor_2 === 4095 ? ultimoDato.sensor_2 : 0,
        ultimoDato.sensor_3 === 4095 ? ultimoDato.sensor_3 : 0,
      ];

      const newChart = new Chart(ctx, {
        type: chartType,
        data: {
          labels: ['Sensor 1', 'Sensor 2', 'Sensor 3'],
          datasets: [{
            label: 'Valores del Último Dato',
            data: sensorData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });

      setChart(newChart);
    }
  }, [esp32Data]);

  // Función para determinar si el dato está disponible u ocupado
  const obtenerEstadoDato = (dato) => {
    return dato === 4095 ? 'Disponible' : 'Ocupado';
  };

  // Imprimir el último dato en la consola para verificar
  useEffect(() => {
    console.log('Último dato:', ultimoDato);
  }, [ultimoDato]);

  return (
    <div className="vista-container">
      <h2>Datos del ESP32</h2>
      {esp32Data && esp32Data.length > 0 ? (
        <canvas id="myChart" width="400" height="400"></canvas>
      ) : (
        <p>No hay datos disponibles</p>
      )}

      {/* Mostrar el último dato */}
      {ultimoDato && (
        <div className="ultimo-dato">
          <h3>Último Dato</h3>
          <ul>
            <li><strong>Sensor 1:</strong> {ultimoDato.sensor_1} ({obtenerEstadoDato(ultimoDato.sensor_1)})</li>
            <li><strong>Sensor 2:</strong> {ultimoDato.sensor_2} ({obtenerEstadoDato(ultimoDato.sensor_2)})</li>
            <li><strong>Sensor 3:</strong> {ultimoDato.sensor_3} ({obtenerEstadoDato(ultimoDato.sensor_3)})</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Vista;
